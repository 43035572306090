.post-history-mobile{
    padding-bottom: 45px;
    background-color: #f0f0f0;
    .post-history-mobile-nav{
        height: 50px;
        box-sizing: border-box;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        background: #fff;
        span{
            width: 37px;
            height: 26px;
            background-color: #d80011;
            color: #fff;
            border-radius: 5px;
            text-align: center;
            line-height: 26px;
        }
    }
    .post-history-list{
        display: flex;
        // align-items: center;
        height: 115px;
        border-bottom: 1px solid #c4c4c4;
        padding-top: 5px;
        background-color: #fff;
        .left{
            width: 25%;
            box-sizing: border-box;
            padding-right: 15px;
            text-align: right;
            display: flex;
            flex-direction: column;
            p{
                margin: 0;
                border: 0;
                height: 24px;
                line-height: 30px;
                color: #d80011;
                font-size: 12px;
            }
        }
        .center{
            flex: 1;
            display: flex;
            flex-direction: column;
            p{
                margin: 0;
                border: 0;
                height: 24px;
                line-height: 1.8rem;
            }
            .lotteryNum{
                padding-top: 5px !important;
                font-size: 12px;
                font-weight: 500;
                li{
                    width: 1.8rem;
                    margin-right: 0px;
                    img{
                        height: 1.8rem;
                        width: 1.8rem;
                    }
                    span{
                        z-index: 1;
                        line-height: 1.8rem;
                    }
                    p{
                        font-size: 12px;
                        line-height: 1rem;
                        margin: 0;
                    }
                }
                li:nth-child(6){
                    margin-right: 12px;
                }
                .add{
                    top: 0.2rem;
                    left: 10.3rem;
                    font-size: 17px;
                } 
            }
        }
        .right{
            
            width: 12%;
            display: flex;
            display: none;
            justify-content: left;
            box-sizing: border-box;
            padding-top: 60px;
            img{
                // display: none;
                width: 18px;
                height: 14px;
            }
        }
    }
    .post-history-mobile-foot{
        width: 70%;
        height: 40px;
        margin: 0 auto;
        text-align: center;
        border-bottom: 1px solid #c5c3c3;
        position: relative;
        span{
            width: 40%;
            height: 30px;
            position: absolute;
            left: 50%;
            bottom: -20px;
            margin-left: -20%;
            background-color: #f0f0f0;
        }
    }
}
@media screen and (max-width:1024px){
    .post-history-mobile{
        .liveShow{
            width: 100%;
        }
        .cha{
            background-color: #fff !important;
        }
    }
    
}