.full-screen{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: #000;
}
.exit-fullScreen-btn {
    position: absolute;
    color: #fff;
    width: 25px;
    height: 25px;
    bottom: 10px;
    right: 50px;
    z-index: 20;
}
.exit-fullScreen-btn img {
    width: 100%;
}
.bags{
    position: absolute;
    top: 22%;
    left: 98px;
    height: 6.5%;
    width: 20%;
    background: url('../../../src/img/lhc_4.png') no-repeat;
    background-size: cover;
}
@media screen and (max-width:1024px){
    .bags{
        top: 5.4rem;
        left: 0;
        height: 1.9rem;
        width: 10rem;
    }
    
}

