.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
ul li{
  list-style: none;
  padding: 0 ;
  margin: 0;
}
.blues{
  background-color: #c9eeff;
  border: 1px solid #0073a7;
  color: #0073a7;
  p{
    a{
      color:#0073a7 !important;
    }
  }
}
.reds{
  background-color: #ffc9c9;
  border: 1px solid #d80011;
  color: #d80011;
}
.red{
  color: #d80011;
  padding: 0 5px;
}
.noDatas{
  height: 30px;
  text-align: center;
  line-height: 30px;
}
.span_active{
  background-color: #d80011;
  color: #fff;
}
.navHeader{
  height: 36px;
            width: 100%;
            line-height: 36px;
            background-color: #f0f0f0;
            border-radius: 18px;
            display: flex;
            span{
                padding: 0 25px;
                height: 36px;
                line-height: 36px;
                border-radius: 18px;
                text-align: center;
                cursor: pointer;
            }
}
.lotteryNum{
    display: flex;
    padding: 0 !important;
    // font-size: 20px;
    // font-weight: 500;
    position: relative;
    li{
        position: relative;
        // width: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #000;
        // margin-right: 6px;
        img{
            position: absolute;
            top: 0;
            left: 0;
            // height: 60px;
            // width: 60px;
        }
        span{
            z-index: 1;
            // line-height: 55px;
        }
        p{
            // font-size: 14px;
            // line-height: 35px;
        }
    }
    li:nth-child(6){
        // margin-right: 20px;
    }
    .add{
        position: absolute;
        // top: 8px;
        // right: 62px;
        color: red;
    }                        
}
.liveShow{
  height: 100%;
  width: 100%;
  // min-width: 1170px;
  // min-width: 660px;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 1;
  .contens{
      position: absolute;
      top:50%;
      left: 50%;
      height: 450px;
      width: 640px;
      background-color: #fff;
      margin-left: -320px;
      margin-top: -225px;
      .cha{
          height: 25px;
          width: 25px;
          border-radius: 25px;
          background-color: #c1c1c1;
          position: absolute;
          top: 12px;
          right: 12px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img{
              width: 15px;
              height: 15px;
          }
      }
  }
}
@media screen and (max-width:1024px){
  .navHeader{
    height: 40px;
    width: 100%;
    line-height: 40px;
    border-radius: 0px;
      span{
        padding: 0 25px;
        height: 40px;
        line-height: 40px;
        border-radius: 0px;
      }
      .span_active{
        color: #fff100 !important;
      }
  }
  .liveShow{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 3;
    .contens{
        position: absolute;
        top:50%;
        left: 50%;
        height: 312px;
        width: 96%;
        background-color: #fff;
        margin-left: -48%;
        margin-top: -156px;
        border-radius: 5px 5px 0 0;
        .cha{
            height: 25px;
            width: 25px;
            border-radius: 25px;
            background-color: #c1c1c1;
            position: absolute;
            top: 4px;
            right: 4px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img{
                width: 15px;
                height: 15px;
            }
        }
    }
  }
}

