.bgVideoBox{
    width: 100%;
    height: 100%;
}
.open-live {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    background: #000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

bgvideo {
    display: block!important;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 20;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 30px;
    height: 40px;
    font-size: 10px
}

.pause,.play{
    position: absolute;
    z-index: 20;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.pause{
    width: 37px;
    height: 37px;
    border-style: double;
    border-width: 0px 0px 0px 30px;
    border-color: #202020;
}

.play{
    height: 37px;
    border-style: solid;
    border-width: 18.5px 0 18.5px 30px;
    border-color: transparent transparent transparent #fff;;
}

.spinner>div {
    background-color: #fff;
    height: 100%;
    width: 6px;
    margin-right: 2.5px;
    display: inline-block;
    -webkit-animation: stretchdelay 1s ease-in-out infinite;
    animation: stretchdelay 1s ease-in-out infinite
}
.refresh-btn{
    position: absolute;
    color: #fff;
    width: 25px;
    height: 25px;
    bottom: 50px;
    right: 5px;
    z-index: 20;
    background: url('../../img/Refreshs.png') no-repeat;
    background-size: cover;
}
.refresh-btn-active{
    animation: rotate 1.2s infinite;
}
.open-fullScreen-btn {
    position: absolute;
    color: #fff;
    width: 25px;
    height: 25px;
    bottom: 5px;
    right: 5px;
    z-index: 20;
}
.open-fullScreen-btn img {
    width: 100%;
}

.spinner .rect2 {
    -webkit-animation-delay: -.9s;
    animation-delay: -.9s
}

.spinner .rect3 {
    -webkit-animation-delay: -.8s;
    animation-delay: -.8s
}

@-webkit-keyframes stretchdelay {
    0%,40%,to {
        transform: scaleY(.4);
        -webkit-transform: scaleY(.4)
    }

    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1)
    }
}

@keyframes stretchdelay{
    0%,40%,to {
        transform: scaleY(.4);
        -webkit-transform: scaleY(.4)
    }

    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1)
    }
}
@keyframes rotate {
    0%{
        transform:rotate(0deg);
        -ms-transform:rotate(0deg);     /* IE 9 */
        -moz-transform:rotate(0deg);    /* Firefox */
        -o-transform:rotate(0deg);
    }
    100%{
        transform:rotate(360deg);
        -ms-transform:rotate(360deg);     /* IE 9 */
        -moz-transform:rotate(360deg);    /* Firefox */
        -o-transform:rotate(360deg);
    }
}
@-webkit-keyframes rotate {
    0%{
        -moz-transform:rotate(0deg);
    }
    100%{
        -moz-transform:rotate(360deg);
    }
}